import {clone} from "@/common/js/tool";
import {educationList, maritalStatus, politicalList, sexList, staffTypes} from "@/common/hr/book";
import {getKeyTitle} from "@/common/js/tool";
import {generateValidator} from "@/components/custom-form/formItems";
import {omit} from "../js/tool";
// const idCardValidator = generateValidator(/^[1-9]\d{5}(18|19|20)\d{2}((0[1-9])|(1[0-2]))(([0-2][1-9])|10|20|30|31)\d{3}[0-9Xx]$/, "请输入正确的身份证号");

export const typeForm = {
    base: {
        items: [
            {
                key: 'type',
                label: '身份',
                component: 'select',
                placeholder: '请选择',
                options: staffTypes,
                rangeKey: "title",
                valueKey: "key",
                rules: [{
                    type: 1,
                    title: '必填',
                    rule: { required: true, message: '请选择身份' },
                }],
                span: 12,
            },
        ]
    },
    // 进单
    monk: {
        type: 1,
        items: [
            {
                component: 'input',
                type: 'text',
                label: '法名',
                key: 'fm',
                placeholder: '请输入',
                span: 12,
                rules: [{
                    type: 1,
                    title: '必填',
                    rule: { required: true, message: '请输入法名' },
                }],
            },
            {
                component: 'select',
                mode: 'date',
                label: '进单日期',
                key: 'entry_date',
                placeholder: '请选择进单日期',
                span: 12,
                rules: [{
                    type: 1,
                    title: '必填',
                    rule: { required: true, message: '请选择进单日期' },
                }],
            },
            {
                component: 'input',
                type: 'text',
                label: '联系电话',
                key: 'tel',
                placeholder: '请输入',
                span: 12,
                rules: [
                    {
                        type: 1,
                        title: '必填',
                        rule: { required: true, message: '请输入联系电话' },
                    },
                    {
                        type: 2,
                        title: '手机号格式校验',
                        rule: { regex: '^1[3-9]\\d{9}$', message: '请输入正确的手机号码' },
                    }
                ],
            },
            {
                component: 'input',
                type: 'text',
                label: '部门',
                key: 'dept',
                placeholder: '请输入部门',
                span: 12,
            },
            {
                component: 'input',
                type: 'text',
                label: '法号',
                key: 'fh',
                placeholder: '请输入',
                span: 12,
            },
            {
                component: 'input',
                type: 'text',
                label: '俗名',
                key: 'name',
                placeholder: '请输入',
                span: 12,
            },
            {
                component: 'select',
                mode: 'date',
                label: '出生日期',
                key: 'birthday',
                placeholder: '请选择出生日期',
                span: 12,
            },
            {
                component: 'input',
                type: 'idcard',
                label: '身份证号',
                key: 'id_card',
                placeholder: '请输入',
                span: 12,
            },
            {
                component: 'input',
                type: 'text',
                label: '民族',
                key: 'nation',
                placeholder: '请输入',
                span: 12,
            },
            {
                component: 'input',
                type: 'text',
                label: '剃度师',
                key: 'barber',
                placeholder: '请输入',
                span: 12,
            },
            {
                component: 'input',
                type: 'text',
                label: '得戒师',
                key: 'teacher',
                placeholder: '请输入',
                span: 12,
            },
            {
                component: 'select',
                mode: 'selector',
                label: '学历',
                placeholder: '请选择',
                key: 'education',
                rangeKey: "title",
                valueKey: "key",
                options: [...educationList],
                span: 12,
            },
            {
                component: 'input',
                type: 'text',
                label: '毕业院校',
                key: 'school',
                placeholder: '请输入',
                span: 12,
            },
            {
                component: 'select',
                mode: 'date',
                label: '出家时间',
                key: 'monk_time',
                placeholder: '请选择出家时间',
                span: 12,
            },
            {
                component: 'input',
                type: 'text',
                label: '出家寺院',
                key: 'monk_temple',
                placeholder: '请输入',
                span: 12,
            },
            {
                component: 'select',
                mode: 'date',
                label: '受戒年月',
                key: 'ordained_time',
                placeholder: '请选择受戒年月',
                fields: "month",
                span: 12,
            },
            {
                component: 'input',
                type: 'text',
                label: '受戒地址',
                key: 'monk_address',
                placeholder: '请输入',
                span: 12,
            },
            {
                component: 'input',
                type: 'text',
                label: '户籍所在',
                key: 'census',
                placeholder: '请输入',
                span: 12,
            },
            {
                component: 'upload',
                label: '照片',
                key: 'photo',
                limit: 9,
            },
            {
                component: 'input',
                type: 'textarea',
                label: '个人简历',
                key: 'resume',
                placeholder: '请输入',
            },
            {
                component: 'input',
                type: 'textarea',
                label: '社会关系',
                key: 'relations',
                placeholder: '请输入',
            },
            {
                component: 'input',
                type: 'textarea',
                label: '来寺因缘',
                key: 'temple_fate',
                placeholder: '请输入',
            },
        ]
    },
    // 离单
    um: {
        type: 2,
        items: [
            {
                component: 'select',
                mode: 'date',
                label: '离单日期',
                key: 'leave_date',
                placeholder: '请选择离单日期',
                rules: [{
                    type: 1,
                    title: '必填',
                    rule: { required: true, message: '请选择离单日期' },
                }],
            },
            {
                component: 'input',
                type: 'textarea',
                label: '离单原因',
                key: 'reason',
                placeholder: '请输入离单原因',
                rules: [{
                    type: 1,
                    title: '必填',
                    rule: { required: true, message: '请输入离单原因' },
                }],
            },
        ]
    },
    // 入职
    board: {
        type: 3,
        items: [
            {
                component: 'input',
                type: 'text',
                label: '姓名',
                key: 'name',
                placeholder: '请输入',
                span: 12,
                rules: [{
                    type: 1,
                    title: '必填',
                    rule: { required: true, message: '请输入姓名' },
                }],
            },
            {
                component: 'input',
                type: 'text',
                label: '联系电话',
                key: 'tel',
                placeholder: '请输入',
                span: 12,
                rules: [
                    {
                        type: 1,
                        title: '必填',
                        rule: { required: true, message: '请输入联系电话' },
                    },
                    {
                        type: 2,
                        title: '手机号格式校验',
                        rule: { regex: '^1[3-9]\\d{9}$', message: '请输入正确的手机号码' },
                    }
                ],
            },
            {
                component: 'select',
                mode: 'date',
                label: '入职日期',
                key: 'entry_date',
                placeholder: '请选择入职日期',
                span: 12,
                rules: [{
                    type: 1,
                    title: '必填',
                    rule: { required: true, message: '请选择入职日期' }
                }],
            },
            {
                component: 'input',
                type: 'text',
                label: '部门',
                key: 'dept',
                placeholder: '请输入部门',
                span: 12,
            },
            {
                component: 'select',
                mode: 'selector',
                label: '性别',
                placeholder: '请选择',
                key: 'sex',
                rangeKey: "title",
                valueKey: "key",
                options: [...sexList],
                span: 12,
            },
            {
                component: 'select',
                mode: 'selector',
                label: '学历',
                placeholder: '请选择',
                key: 'education',
                rangeKey: "title",
                valueKey: "key",
                options: [...educationList],
                span: 12,
            },
            {
                component: 'input',
                type: 'text',
                label: '曾用名',
                key: 'used_name',
                placeholder: '请输入',
                span: 12,
            },
            {
                component: 'input',
                type: 'text',
                label: '民族',
                key: 'nation',
                placeholder: '请输入',
                span: 12,
            },
            {
                component: 'input',
                type: 'text',
                label: '籍贯',
                key: 'home',
                placeholder: '请输入',
                span: 12,
            },
            {
                component: 'select',
                mode: 'date',
                label: '出生日期',
                key: 'birthday',
                placeholder: '请选择出生日期',
                span: 12,
            },
            {
                component: 'input',
                type: 'text',
                label: '专业技巧',
                key: 'skill',
                placeholder: '请输入',
                span: 12,
            },
            {
                component: 'input',
                type: 'text',
                label: '健康状况',
                key: 'health',
                placeholder: '请输入',
                span: 12,
            },
            {
                component: 'select',
                mode: 'selector',
                label: '政治面貌',
                placeholder: '请选择',
                key: 'political',
                rangeKey: "title",
                valueKey: "key",
                options: [...politicalList],
                span: 12,
            },
            {
                component: 'input',
                type: 'text',
                label: '宗教信仰',
                key: 'religion',
                placeholder: '请输入',
                span: 12,
            },
            {
                component: 'select',
                mode: 'selector',
                label: '婚姻状况',
                placeholder: '请选择',
                key: 'marital',
                rangeKey: "title",
                valueKey: "key",
                options: [...maritalStatus],
                span: 12,
            },
            {
                component: 'input',
                type: 'idcard',
                label: '身份证号',
                key: 'id_card',
                placeholder: '请输入',
                span: 12,
            },
            {
                component: 'input',
                type: 'text',
                label: '原工作单位',
                key: 'old_company',
                placeholder: '请输入',
                span: 12,
            },
            {
                component: 'input',
                type: 'text',
                label: '职务',
                key: 'post',
                placeholder: '请输入',
                span: 12,
            },
            {
                component: 'input',
                type: 'text',
                label: '家庭住址',
                key: 'address',
                placeholder: '请输入',
            },
            {
                component: 'upload',
                label: '照片',
                key: 'photo',
                limit: 9,
            },
            {
                component: 'input',
                type: 'textarea',
                label: '本人经历',
                key: 'experience',
                placeholder: '请输入',
            },
            {
                component: 'input',
                type: 'textarea',
                label: '社会关系',
                key: 'relations',
                placeholder: '请输入',
            },
        ]
    },
    // 离职
    ub: {
        type: 4,
        items: [
            {
                component: 'select',
                mode: 'date',
                label: '离职日期',
                key: 'leave_date',
                placeholder: '请选择离职日期',
                rules: [{
                    type: 1,
                    title: '必填',
                    rule: { required: true, message: '请选择离职日期' }
                }],
            },
            {
                component: 'input',
                type: 'textarea',
                label: '离职原因',
                key: 'reason',
                placeholder: '请输入离职原因',
                rules: [{
                    type: 1,
                    title: '必填',
                    rule: { required: true, message: '请输入离职原因' }
                }],
            },
        ]
    },
    // 调岗
    trans: {
        type: 5,
        items: [
            {
                key: 'staff',
                label: '调岗人员',
                component: 'book',
                type: 'user',
                placeholder: '请选择调岗人员',
                rules: [{
                    type: 1,
                    title: '必填',
                    rule: { required: true, message: '请选择调岗人员' }
                }]
            },
            {
                key: 'old_dept',
                label: '原部门',
                component: 'book',
                type: 'department',
                placeholder: '请选择原部门',
                rules: [{
                    type: 1,
                    title: '必填',
                    rule: { required: true, message: '请选择原部门' }
                }]
            },
            {
                key: 'old_post',
                label: '原部门岗位',
                component: 'input',
                type: "text",
                placeholder: '请输入',
                rules: [{
                    type: 1,
                    title: '必填',
                    rule: { required: true, message: '请输入原部门岗位' }
                }]
            },
            {
                key: 'new_dept',
                label: '申请部门',
                component: 'book',
                type: 'department',
                placeholder: '请选择申请部门',
                rules: [{
                    type: 1,
                    title: '必填',
                    rule: { required: true, message: '请选择申请部门' }
                }]
            },
            {
                key: 'new_post',
                label: '申请部门岗位',
                component: 'input',
                type: "text",
                placeholder: '请输入',
                rules: [{
                    type: 1,
                    title: '必填',
                    rule: { required: true, message: '请输入申请部门岗位' }
                }]
            },
            {
                key: 'reason',
                label: '申请原因',
                component: 'input',
                type: "textarea",
                placeholder: '请输入申请原因',
                rules: [{
                    type: 1,
                    title: '必填',
                    rule: { required: true, message: '请输入申请原因' }
                }]
            },
        ]
    }
}

export const getTypeForm = function (type) {
    let temp = typeForm[type];
    return temp ? convertFormToAnt(clone(temp.items)) : null;
}

export const getNumberTypeForm = function (type) {
    let key = Object.keys(typeForm).find(k => typeForm[k].type == type);
    return key ? clone(typeForm[key].items) : null;
}

export const getFormTypeNum = function (type) {
    let temp = typeForm[type];
    return temp ? clone(temp.type) : null;
}

/**
 * 转换input组件
 * @param item
 */
export const getAntInputComponent = function (item) {
    let res = "a-input";
    if(item.type == "number") {
        res = "a-input-number";
    } else if(item.type == "textarea") {
        res = "a-textarea";
    }
    return res;
}

/**
 * 转换校验规则
 * @param rule
 * @param item
 */
export const convertFormRule = function (rule, item) {
    let res = {...rule};
    if(res.regex) {
        res = { validator: generateValidator(new RegExp(res.regex), res.message) };
    }
    let e = "change";
    if(item.component == "a-input") {
        e = "blur";
    }
    res.trigger = e;
    return res;
}

/**
 * 将小程序表单配置转化成 ant form 表单配置
 * @param form 小程序表单配置数组
 */
export const convertFormToAnt = function (form) {
    let res = [];
    if(form && form.length > 0) {
        res = form.map(item => {
            const con = {
                key: item.key,
                label: item.label,
                component: `cl-${item.component}`,
                props: omit(item, ["key", "label", "component", "rules"]),
            }
            switch (item.component) {
                case "input":
                    con.component = getAntInputComponent(item);
                    con.props = {
                        placeholder: item.placeholder,
                    }
                    if(con.component == "a-textarea") {
                        con.props.rows = 4;
                    }
                    break;
                case "upload":
                    con.component = "file-upload";
                    con.props = {
                        limit: item.limit
                    }
                    break;
                case "book":
                    con.component = "select-book";
                    con.props = {
                        type: item.type == "department" ? "dept" : "user"
                    }
                    if(item.multiple) {
                        con.props.multiple = true;
                    }
                    break;
                case "tk":
                    con.listeners = {
                        change(val) {
                            if(val) {
                                let con = this.controls.find(c => c.key == "manager");
                                const ids = val.admin_user_ids ? val.admin_user_ids.split(",") : [];
                                if(con) {
                                    const range = ids.map(id => {
                                        return {
                                            userid: id,
                                            name: id,
                                        }
                                    });
                                    this.$set(con.props, "range", range);
                                    if(ids.length > 0) {
                                        this.$set(this.form, "manager", ids[0]);
                                    }
                                }
                                con = this.controls.find(c => c.key == "subscribe");
                                if(con) {
                                    this.$set(con.props, "tkId", val.id);
                                }
                            }
                        }
                    }
                    break;
            }
            if(item.rules) {
                con.rules = item.rules.map(r => convertFormRule(r.rule, con));
            }
            if(item.span) {
                con.span = item.span;
            }
            return con;
        });
    }
    return res;
}

export const getStaffDetailList = function (user) {
    let controls = [];
    let type = user.type;
    if(type == 1) {
        controls = typeForm.monk.items;
    } else if(type == 2) {
        controls = typeForm.board.items;
    }
    return controls.map(item => {
        let val = {
            label: item.label,
            key: item.key,
            value: user[item.key]
        }
        // 兼容 relations 是json字符串的情况
        if (item.key === "relations") {
            try {
                const relations = JSON.parse(user.relations);
                val.value = relations && relations.length > 0 ? relations.map(r => {
                    return Object.keys(r).map(k => {
                        let p = r[k];
                        if (Array.isArray(p)) {
                            p = p.join("");
                        }
                        return `${k}：${p}`;
                    }).join("；");
                }).join("\n") : ""
            } catch (e) {
                console.log("relations is string")
            }
        }
        // 兼容 relations 是json字符串的情况
        if (item.key === "resume") {
            try {
                const resume = JSON.parse(user.resume);
                val.value = resume && resume.length > 0 ? resume.map(r => {
                    return Object.keys(r).map(k => {
                        let p = r[k];
                        if (Array.isArray(p)) {
                            p = p.join(p[p.length - 1].includes("至") ? "" : "至");
                        }
                        return `${k}：${p}`;
                    }).join("；");
                }).join("\n") : ""
            } catch (e) {
                console.log("relations is string")
            }
        }
        if(item.component === 'select') {
            let options = item.options;
            if(options && options.length > 0) {
                let opt = options[0];
                if(typeof opt === 'object') {
                    val.value = getKeyTitle(options, val.value);
                }
            }
        } else if(item.component === 'upload') {
            if(val.value) {
                if(Array.isArray(val.value)) {
                    val.imgList = val.value.map(m => m.url);
                } else {
                    val.imgList = val.value.split(",");
                }
            } else {
                val.imgList = [];
            }
        } else if(item.component === 'input' && item.type === "textarea") {
            val.textarea = true;
        } else if(item.component === 'rich-editor') {
            val.rich = true;
        }
        return val;
    });
}
